<template>
    <div class="password">

        <h2>Keine Sorge, wir helfen dir gerne!</h2>
        <p>Teile uns einfach deine E-Mail-Adresse mit. Wir schicken dir anschließend einen Link zum Zurücksetzen deines Passwortes zu.</p>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field validate-on-blur v-model="emailAddress" name="email" placeholder="E-Mail-Adresse" type="email" :rules="emailRules" required></v-text-field>
        </v-form>
        <v-btn :disabled="!valid" class="button button--primary" @click="requestNewPassword">Passwort zurücksetzen</v-btn>
        
        <SuccessNotificationModal @closeModal="closeModal" title="Hinweis" message="Bitte überprüfe deine Emails, um dein Passwort zurückzusetzen."></SuccessNotificationModal>
    </div>
</template>
<script>
import firebase from 'firebase';
import SuccessNotificationModal from '@/components/SuccessNotificationModal.vue';

export default {
    name: 'PasswordForgot',
    components: { SuccessNotificationModal },
    data() {
        return {
            valid: false,
            emailAddress: "",
            emailRules: [
                v => !!v || 'Eine Email-Adresse ist notwendig',
                v => /.+@.+/.test(v) || 'Die Adresse ist nicht im gültigen Format.'
            ],
        }
    },
    methods: {
        requestNewPassword() {
            if (this.emailAddress.length > 0) {
                console.log("send new password");
                firebase.auth().sendPasswordResetEmail(
                    this.emailAddress)
                    .then(() => {
                        // Password reset email sent.
                        this.$modal.show("successNotification");
                    })
                    .catch((error) => {
                        console.log(error.message);
                        alert("Bitte überprüfe deine Email-Adresse.");
                        // Error occurred. Inspect error.code.
                    });
            }
        },
        closeModal() {
            this.$router.push("/");
        }
    }
};
</script>

<style lang="scss">
    .password {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        text-align: center;

        h2 {
            margin: 24px 0 16px;
        }

        p {
            margin-bottom: 32px;
        }

        .button {
            margin-top: auto;
        }
    }
</style>